import {registerComponent} from "tim-bridge";
import {run} from "./runner";
import SearchConfigurationDetailsScreen from "./components/detailsscreen/SearchConfigurationDetailsScreen";
import SearchConfigurationList from "./components/configurationlist/SearchConfigurationList";

const config = require("../tim-meta.json");
const parameters = require("../app-parameters.json");
const isProduction = process.env.NODE_ENV === "production";
const isStandalone = process.env.BUILD_VARIANT === "standalone";

const components = {
    SearchConfigurationDetailsScreen,
    SearchConfigurationList
};

if (isProduction && !isStandalone) {
    config
        .filter(c => c.componentName in components)
        .forEach(c => {
            registerComponent(c.packageName, c.componentName, components[c.componentName]);
        });
} else {
    run("DevSearchConfiguration", parameters, isStandalone);
}