import {SearchConfiguration} from "../shared/types";
import {Button, Dialog} from "tim-ui";
import * as React from "react";

export type DeleteConfigurationDialogProps =  {
    isOpen: boolean,
    onClose: (ok: boolean) => void
    data: SearchConfiguration
};

const DeleteConfigurationDialogComponent = (props: DeleteConfigurationDialogProps) => {
    const {isOpen, onClose, data} = props;
    const ok = () => onClose(true);
    const cancel = () => onClose(false);
    const actions = [
        <Button onClick={ok}>OK</Button>,
        <Button color={"secondary"} onClick={cancel}>Cancel</Button>
    ];
    const content = <div>Delete Configuration: "{data.name} ({data.id})" ?</div>;
    return <Dialog
        isOpen={isOpen}
        dialogTitle={"Delete Configuration"}
        dialogContent={content}
        dialogActions={actions}
        onDialogClose={cancel}
    />;
};

export const DeleteConfigurationDialog = (props: DeleteConfigurationDialogProps) => props.isOpen
    ? <DeleteConfigurationDialogComponent {...props} />
    : <></>;