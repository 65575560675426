import {DataSelection, ObjectType, SearchConfiguration} from "./types";
import {Dialog, Button} from "tim-ui";
import * as React from "react";
import {FormField, TextInput} from "@stibo/value-components";
import TypeaheadSearch from "../typeahead/TypeaheadSearch";
import {GET_OBJECT_TYPES} from "../typeahead/Queries";

const EditConfigurationDialogComponent = (props: EditConfigurationDialogProps) => {
    const {isOpen, onClose, ...state} = props;
    const save = () => onClose(true);
    const cancel = () => onClose(false);
    const create = props.data?.id === undefined;
    const title = create ? "Create Search Configuration" : "Edit Search Configuration";

    const actions = [
        <Button onClick={save}>OK</Button>,
        <Button color={"secondary"} onClick={cancel}>Cancel</Button>
    ];

    return <Dialog
        isOpen={isOpen}
        dialogTitle={title}
        dialogContent={<SearchConfigurationEditor {...state} />}
        dialogActions={actions}
        onDialogClose={cancel}
    />;
};

type SearchConfigurationEditorProps = {
    data: SearchConfiguration,
    setData: (SearchConfiguration) => void
};
const SearchConfigurationEditor = ({data, setData}: SearchConfigurationEditorProps) => {
    const objectType = data.objectType || {id: "", name: ""};
    const dataSelection: DataSelection = {
        type: "OBJECT_TYPE",
        name: objectType.name,
        id: objectType.id
    };

    const setDataSelection = (ds: DataSelection) => {
        setObjectType({id: ds.id, name: ds.name});
    };

    function setName(value) {
        setData({...data, name : value});
    }

    function setObjectType(value) {
        setData({...data, objectType: value});
    }

    return <>
        <FormField name="Name" title="Name">
            <TextInput
                name="name"
                value={data.name}
                onChange={e => setName(e.target.value)}
            />
        </FormField>
        <TypeaheadSearch
            title={"Vehicle Object type"}
            query={GET_OBJECT_TYPES}
            onChange={setDataSelection}
            value={dataSelection} />
    </>;
};

export type EditConfigurationDialogProps = {
    isOpen: boolean,
    onClose: (save: boolean) => void
    data: SearchConfiguration
    setData: (SearchConfiguration) => void
};

export const EditConfigurationDialog = (props: EditConfigurationDialogProps) => props.isOpen
    ? <EditConfigurationDialogComponent {...props} />
    : <></>;