import * as React from "react";
import {
    BridgeProps,
    WithGraphClient,
    withGraphqlEndpoint
} from "tim-bridge";
import {useEffect, useState} from "react";
import {IntlProvider} from "react-intl";
import {getSearchConfigurationList} from "./graphql/getSearchConfigurationList.query";
import {createSearchConfiguration} from "./graphql/createSearchConfiguration.mutation";
import {SearchConfiguration} from "../shared/types";
import {EditConfigurationDialog} from "../shared/EditConfigurationDialog";
import {StiboThemeProvider} from "@stibo/value-components";
import {Button,  ThemeProvider} from "tim-ui";
import styled from "styled-components";

const SearchConfigurationListComponent: React.FC<BridgeProps & WithGraphClient> = props => {
    const [allConfigurations, setAllConfigurations] = useState([]);
    const [createNew, setCreateNew] = useState<SearchConfiguration>(undefined);
    const [selection, setSelection] = useState(() => props.callbacks.getSelection());
    const showEditDialog = createNew !== undefined;
    const registerNotificationHandler = props.callbacks.registerNotificationHandler;

    function showError(headline: string, message: string) {
        props.callbacks.showAlert("error", headline, message);
    }

    function navigateToHandler(sc: SearchConfiguration) {
        const selection = {
            __typename: "AutomotiveSearchConfiguration",
            stepId: sc.id
        };
        props.callbacks.navigateTo(selection);
    }

    function createSearchConfigurationHandler(save: boolean) {
        if (save) {
            createSearchConfiguration(props.client, createNew)
                .then(_ => setCreateNew(undefined))
                .catch(e => {
                    setCreateNew(undefined);
                    console.error(e);
                });
        } else {
            setCreateNew(undefined);
        }
    }

    function createNewHandler() {
        setCreateNew({id: undefined, name: "", objectType: {id: "", name: ""}});
    }

    function loadData() {
        getSearchConfigurationList(props.client)
            .then(setAllConfigurations)
            .catch(error => showError("Load Configuration Error", error.message));
    }

    registerNotificationHandler(() => {
        setSelection(props.callbacks.getSelection());
        loadData();
    });

    useEffect(() => {
        // only load updates when dialogs are closed
        if (! (showEditDialog)) {
            loadData();
        }
    }, [showEditDialog]);

    return <>
        <Button color="secondary" onClick={createNewHandler}>Create New</Button>

        <EditConfigurationDialog
            isOpen={showEditDialog}
            onClose={createSearchConfigurationHandler}
            data={createNew}
            setData={setCreateNew}
        />

        <ConfigurationList
            data={allConfigurations}
            selection={selection}
            onClick={navigateToHandler}
            />
    </>;
};

function isSelected(id: string, selection: {nodeType?: string, nodeId?: string}) {
    const nodeType = selection?.nodeType;
    const nodeId = selection?.nodeId;
    return nodeType === "SetupEntity" &&  nodeId === id;
}

const ConfigurationList = ({data, selection, onClick}) => {
    return <ListStyle>
        {
            data.map(sc => <Row
                key={sc.id}
                searchConfiguration={sc}
                selected={isSelected(sc.id, selection)}
                onClick={() => onClick(sc)}
            />)
        }
    </ListStyle>;
};

type RowProps = {
    searchConfiguration: SearchConfiguration,
    selected: boolean
    onClick: () => void
};
const Row = (props: RowProps) => {
    const {searchConfiguration, selected, onClick} = props;
    const name = searchConfiguration.name;
    const nameAndId = name + " [" + searchConfiguration.id + "]";
    return <RowStyle selected={selected} onClick={onClick}>
        <span title={nameAndId}>{name}</span>
    </RowStyle>;
};

const RowStyle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    height: 24px;
    padding-left: 8px;
    background-color: ${props => props.selected ? "#138dc9" : "auto" };
    color: ${props => props.selected ? "white" : "auto" };
    & > :fist-child {
        overflow: hidden;
        text-overflow: ellipsis;
    };
`;

const ListStyle = styled.div`
    display: flex;
    flex-direction: column;
    ${RowStyle}:hover {
        background-color: lightgray;
        cursor: pointer;
    }
`;

const SearchConfigurationListComponentWrapper = (props) => (
    <IntlProvider locale={"en-US"}>
        <ThemeProvider>
            <StiboThemeProvider>
                <SearchConfigurationListComponent {...props} />
            </StiboThemeProvider>
        </ThemeProvider>
    </IntlProvider>
);
export default withGraphqlEndpoint("graphqlv2")(SearchConfigurationListComponentWrapper);