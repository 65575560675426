import * as React from "react";
import {
    BridgeProps,
    withBridgeCallbacks,
    WithGraphClient,
    withGraphqlEndpoint,
    withSelection
} from "tim-bridge";
import {useEffect, useState} from "react";
import {IntlProvider} from "react-intl";
import {SearchConfiguration, SearchConfigurationOption} from "../shared/types";
import {ConfigurationEditor} from "./ConfigurationEditor";
import * as _ from "lodash";
import {getSearchConfigurationById} from "./graphql/getSearchConfigurationById.query";
import {updateSearchConfigurationOptions} from "./graphql/updateSearchConfigurationOptions.mutation";
import {deleteSearchConfiguration} from "./graphql/deleteSearchConfiguration.mutation";
import styled from "styled-components";
import {StiboThemeProvider} from "@stibo/value-components";
import {Add, Button, Edit, IconButton, ThemeProvider} from "tim-ui";
import {getUUID} from "../shared/util";
import {EditConfigurationDialog} from "../shared/EditConfigurationDialog";
import {DeleteConfigurationDialog} from "./DeleteConfigurationDialog";

const SearchConfigurationDetailsScreenComponent: React.FC<BridgeProps & WithGraphClient> = props => {
    const stepId = props.selection?.stepId;
    const [serverData, setServerData] = useState<SearchConfiguration>(null);
    const [editorData, setEditorData] = useState<SearchConfiguration>(null);
    const [deleteConfiguration, setDeleteConfiguration] = useState<SearchConfiguration>(undefined);
    const [editConfiguration, setEditConfiguration] = useState<SearchConfiguration>(undefined);
    const [error, setError] = useState(null);
    const isDirty = ! _.isEqual(serverData, editorData);
    const showDeleteDialog = deleteConfiguration !== undefined;
    const notifyListeners = props.callbacks.notifyListeners;

    useEffect(loadFromServer, [stepId]);
    function saveChanges() {
        updateSearchConfigurationOptions(props.client, editorData)
            .then(_ => notifyListeners())
            .then(_ => loadFromServer())
            .catch(console.error);
    }

    function loadFromServer() {
        if (stepId !== null) {
            getSearchConfigurationById(props.client, stepId)
                .then(data => (data == null || data.options) ? data : {...data, options: []})
                .then(setDataModel)
                .catch(error => {
                    setDataModel(null);
                    setError(error.message);
                });
        } else {
            setDataModel(null);
            setError("No Selection");
            return;
        }
    }

    function setDataModel(data) {
        setEditorData(data);
        setServerData(data);
    }

    function deleteSearchConfigurationHandler(ok: boolean) {
        if (ok) {
            deleteSearchConfiguration(props.client, deleteConfiguration)
                .then(_ => notifyListeners())
                .then(_ => setDeleteConfiguration(undefined))
                .then(_ => loadFromServer())
                .catch(e => {
                    console.error(e);
                    setDeleteConfiguration(undefined);
                });
        } else {
            setDeleteConfiguration(undefined);
        }
    }

    function deleteSelection() {
        setDeleteConfiguration(serverData);
    }

    function appendOption() {
        const option: SearchConfigurationOption = {
            id: getUUID(),
            name: "",
            path: "",
            conditionType: {
                type: "ATTRIBUTE",
                id: "",
                name: ""
            }
        };

        setEditorData({
            ...editorData,
            options: [...editorData.options, option]
        });
    }

    function editSearchConfigurationHandler(ok: boolean) {
        if (ok) {
            setEditorData({
                ...editorData,
                name: editConfiguration.name,
                objectType: editConfiguration.objectType,
            });
        }
        setEditConfiguration(undefined);
    }

    return <>
        <EditConfigurationDialog
            isOpen={editConfiguration !== undefined}
            onClose={editSearchConfigurationHandler}
            data={editConfiguration}
            setData={setEditConfiguration}
        />

        <DeleteConfigurationDialog
            isOpen={showDeleteDialog}
            onClose={deleteSearchConfigurationHandler}
            data={deleteConfiguration}
        />

        <Screen>
        <Title>{editorData === null ? "No Data" : editorData.name}</Title>
        <ToolBar>
            <IconButton
                onClick={() => appendOption()}
                disabled={editorData === null}
                title={"Add Option"}
            >
                <Add/>
            </IconButton>
            <IconButton
                onClick={() => setEditConfiguration(editorData)}
                disabled={editorData === null}
                title={"Edit Configuration"}
            >
                <Edit/>
            </IconButton>
        </ToolBar>
        <MainArea>
            { editorData && <ConfigurationEditor data={editorData} setData={setEditorData} /> }
        </MainArea>
        <ToolBar>
            <Button
                disabled={!isDirty}
                onClick={saveChanges}>Save</Button>
            <Button
                color={"secondary"}
                disabled={!isDirty}
                onClick={loadFromServer}>Reset</Button>
            <Button
                color={"secondary"}
                disabled={editorData === null}
                onClick={deleteSelection}>Delete</Button>
        </ToolBar>
    </Screen>
    </>;
};


const Screen = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
`;

const Title = styled.div`
    font-size: 20px;
    padding-left: 32px;
    padding-top: 16px;
    padding-bottom: 8px;
`;

const MainArea = styled.div`
    flex: auto;
    overflow: auto;
`;
const ToolBar = styled.div`
    display: flex;
    align-items: center;
    height: 44px;
    padding-left: 8px;
    background-color: #f5f5f5;
    & > button {
        margin-right: 8px;
    }
`;

const SearchConfigurationDetailsScreenComponentWrapper = (props) => (
    <IntlProvider locale={"en-us"}>
        <ThemeProvider>
            <StiboThemeProvider>
                <SearchConfigurationDetailsScreenComponent {...props} />
            </StiboThemeProvider>
        </ThemeProvider>
    </IntlProvider>
);

export default withGraphqlEndpoint("graphqlv2")(
    withSelection(
        withBridgeCallbacks(SearchConfigurationDetailsScreenComponentWrapper)
    )
);
