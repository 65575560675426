import {TimGraphApollo, withGraphqlEndpoint} from "tim-bridge";
import {MutationResult, SearchConfiguration} from "../../shared/types";
import gql from "graphql-tag";

const MUTATION_DELETE_SEARCH_CONFIGURATION = gql`
    mutation updateSearchConfiguration(
        $id: ID!
    ) {
        deleteSearchConfiguration(
            id: $id
        ) {
            success
            errors
        }
    }
`;

export const deleteSearchConfiguration = (client: TimGraphApollo, sc: SearchConfiguration): Promise<MutationResult> => {
    return client.mutate({
        mutation: MUTATION_DELETE_SEARCH_CONFIGURATION,
        variables: {
            id: sc.id
        }
    }).then(result => result.data.updateSearchConfiguration);
};
