import gql from "graphql-tag";
import {TimGraphApollo} from "tim-bridge";
import {SearchConfiguration} from "../../shared/types";

const SEARCH_CONFIGURATION_LIST = gql`
    query searchConfigurations {
        searchConfigurations {
            id
            name
            objectType {
                id
                name
            }
        }
    }
`;

export const getSearchConfigurationList = (client: TimGraphApollo): Promise<SearchConfiguration[]> => {
    return client.query({
        query: SEARCH_CONFIGURATION_LIST,
        fetchPolicy: "network-only"
    }).then(result => result.data.searchConfigurations);
};
