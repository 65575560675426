import * as React from "react";
import {hot} from "react-hot-loader";
import SearchConfigurationList from "./configurationlist/SearchConfigurationList";
import SearchConfigurationDetailsScreen from "./detailsscreen/SearchConfigurationDetailsScreen";
import {useState} from "react";
import TypeaheadTestScreen from "./typeahead/TypeaheadTestScreen";

function useSessionState(key: string, initial: any) {
    const [state, setState] = useState(sessionStorage.getItem(key) || initial);
    const updateState = (value) => {
        sessionStorage.setItem(key, value);
        setState(value);
    };
    return [state, updateState];
}



const Main = props => {
    type CList = {
        name: string,
        component: any
    };
    const components: CList[] = [
        {
            name: "Configuration Details",
            component: SearchConfigurationDetailsScreen
        },
        {
            name: "Configuration List",
            component: SearchConfigurationList
        },
        {
            name: "Typeahead Test Screen",
            component: TypeaheadTestScreen
        }
        ];
    const [selected, setSelected] = useSessionState("AppSelectedTabIndex", 0);
    const SelectedComponent = components[selected].component;

    return  <>
        <div style={{
            position: "fixed",
            top: "8px",
            right: "8px",
            zIndex: 99999
        }}>
            <select onChange={e => setSelected(e.target.value)} value={selected}>
                {
                    components.map((object, index) => <option key={index} value={index}>{object.name}</option>)
                }
            </select>
        </div>
        <SelectedComponent {...props} />
    </>;
};

const App = Main;
export const AppWithHotReload = hot(module)(Main);
export default App;
