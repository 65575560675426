import gql from "graphql-tag";
import {DocumentNode} from "apollo-link";

export const GET_OBJECT_TYPES: DocumentNode = gql`
    query getObjectTypeResult(
        $prefix: String!
    ) {
        getObjectTypeResult(
            prefix: $prefix
        ) {
            results {
                id
                value
                type
            }
            pageInfo {
                hasNextPage
            }
        }
    }
`;


export const GET_ATTRIBUTES_AND_REFTYPES: DocumentNode = gql`
    query getAttributeRefTypeResult(
        $prefix: String!
    ) {
        getAttributeRefTypeResult(
            prefix: $prefix
        ) {
            results {
                    id
                    value
                    type
            }
            pageInfo {
                hasNextPage
            }
        }
    }
`;
