import * as React from "react";
import {getDataUpdater} from "../shared/util";
import {
    ConditionType,
    SearchConfigurationOption,
    DataEditorProps,
    ListEditorProps,
    DataSelection
} from "../shared/types";
import {
    FormField,
    IconButton,
    RadioInput,
    TextInput
} from "@stibo/value-components";
import {Delete} from "tim-ui";
import styled from "styled-components";
import TypeaheadSearch from "../typeahead/TypeaheadSearch";
import {GET_ATTRIBUTES_AND_REFTYPES} from "../typeahead/Queries";

export const OptionsList = ({data, setData}: ListEditorProps<SearchConfigurationOption>) => {
    return <>
        {
            data.map(option => <OptionEditor
                key={option.id}
                data={option}
                setData={update}
                onDelete={() => remove(option)}
            />)
        }
    </>;

    function remove(remove) {
        setData(data.filter(o => o.id !== remove.id));
    }

    function update(updated) {
        setData(data.map(option => option.id === updated.id ? updated : option));
    }
};

const OptionEditor = ({data, setData, onDelete}: DataEditorProps<SearchConfigurationOption>) => {
    const updateState = getDataUpdater(data, setData);
    const dataSelection: DataSelection = {
        type: data.conditionType.type,
        name: data.conditionType.name,
        id: data.conditionType.id
    };
    const setDataSelection = (ds: DataSelection) => {
        updateState("conditionType", {
            type: ds.type,
            id: ds.id,
            name: ds.name
        });
    };

    return <OptionWrapper>
        <FormColumn>
            <FormField name={"Search Option Name"} title={"Search Option Name"}>
                <TextInput
                    value={data.name}
                    placeholder={"Search Option Name"}
                    onChange={e => updateState("name", e.target.value)}
                />
            </FormField>

            <FormField name={"Step Path"} title={"Step Path"}>
                <TextInput
                    value={data.path}
                    placeholder={"Step Path"}
                    onChange={e => updateState("path", e.target.value)}
                />
            </FormField>

            <TypeaheadSearch
                title={"Condition Type"}
                query={GET_ATTRIBUTES_AND_REFTYPES}
                onChange={setDataSelection}
                value={dataSelection}
            />
        </FormColumn>
        <ActionColumn>
            <IconButton onClick={onDelete}><Delete/></IconButton>
        </ActionColumn>
    </OptionWrapper>;
};

const OptionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 8px;
`;

const FormColumn = styled.div`
    width: 700px;
    border-bottom: 1px solid grey;
`;

const ActionColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;