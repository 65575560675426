import * as React from "react";
import {FC} from "react";
import styled from "styled-components";
import {
    FormField,
    SingleValue,
    TypeaheadPromise,
    TypeaheadProvider,
    TypeaheadValueInput,
    ValidationMessage
} from "@stibo/value-components";
import {TimGraphApollo, useStepQueryParams, WithGraphClient, withGraphqlEndpoint} from "tim-bridge";
import {DocumentNode} from "apollo-link";
import {DataSelection} from "../shared/types";


const getValues = async (prefix: string, query: DocumentNode, maxCount: number, client: TimGraphApollo, contextStepId: string, workspaceStepId: string) => {
    return await client.query({
        query: query,
        variables: {
            prefix: prefix,
            contextStepId,
            workspaceStepId
        },
        fetchPolicy: "network-only"
    });
};

function convertToSingleValue(ds: DataSelection): SingleValue {
    return {
        valueStepId: ds as unknown as string,
        value: ds.name,
    };
}

function convertToDataSelection(sv: SingleValue): DataSelection {
    return  sv.valueStepId as unknown as DataSelection;
}

type Props = {
    title: string
    query: DocumentNode
    value: DataSelection
    onChange: (value: DataSelection) => void
};

const TypeaheadSearch: FC<Props> = (props: Props & WithGraphClient) => {
            const {
                client,
                    value,
                    onChange
            } = props;

            const valueChangeHandler = (e: {value: SingleValue}) => onChange(convertToDataSelection(e.value));

            const messages: ValidationMessage[] = []; // TODO

            const queryParams = useStepQueryParams();

            const typeaheadFn = (prefix: string, maxCount?: number): Promise<TypeaheadPromise> => {
                if (prefix!.length > 0) {
                    return getValues(prefix, props.query, maxCount ? maxCount : 100, client,
                            queryParams.contextStepId,
                            queryParams.workspaceStepId)
                            .then((values: any) => {
                                const data = values.data;

                                // we don't care about which query has returned the data, just taking the first
                                const keys: string[] = Object.keys(data);
                                if (keys.length === 1) {
                                    let datum = data[keys[0]];
                                    const results = datum.results;
                                    const result = results.map(data => {
                                        return {
                                            node: {
                                                externalId: { id: data.id, type: data.type, name: data.value},
                                                value: data.value,
                                            }
                                        };
                                    });
                                    return {
                                        result: result,
                                        hasNextPage: datum.pageInfo.hasNextPage
                                    };
                                } else {
                                    return {
                                        result: [],
                                        hasNextPage: false
                                    };
                                }
                            });
                } else {
                    return new Promise<TypeaheadPromise>(resolve => {
                        resolve({
                            result: [],
                            hasNextPage: false
                        });
                    });
                }
            };

            return <TypeaheadProvider typeaheadFn={typeaheadFn}>
                <FormField
                        name={"Typeahead search"}
                        locale={"en-US"} // TODO: Get from bridge
                        messages={messages}
                        hasLabel={true}
                        title={props.title}
                >
                    <OverrideMaxWidthStyle>
                        <TypeaheadValueInput
                            value={convertToSingleValue(value)}
                            attribute={{
                                validator: {name: "text"},
                                stepId: "none",
                                title: "none"
                            }}
                            onChange={valueChangeHandler}
                        />
                    </OverrideMaxWidthStyle>
                </FormField>
            </TypeaheadProvider>;
        }
;

const OverrideMaxWidthStyle = styled.span`
    & > div {
        max-width: 100% !important;
    }
`;

const TypeaheadWithGraphql: FC<Props> = withGraphqlEndpoint("graphqlv2")(TypeaheadSearch);
export default TypeaheadWithGraphql;
