import * as React from "react";
import {getDataUpdater, getUUID} from "../shared/util";
import {OptionsList} from "./OptionsEditor";
import {SearchConfiguration, SearchConfigurationOption} from "../shared/types";
import {Add, IconButton} from "tim-ui";

export type ConfigurationEditorProps = {
    data: SearchConfiguration,
    setData: (SearchConfiguration) => void
};

export const ConfigurationEditor = (props: ConfigurationEditorProps) => {
    const {data, setData} = props;
    const updateState = getDataUpdater(data, setData);
    const removeOption = (option) => data.options.filter(o => o.id !== option.id);

    return <>
        <OptionsList
            data={data.options}
            setData={option => updateState("options", option)}
            remove={option => updateState("options", removeOption(option))}
        />
    </>;
};
