import {MutationResult, SearchConfiguration} from "../../shared/types";
import {TimGraphApollo} from "tim-bridge";
import gql from "graphql-tag";


const MUTATION_UPDATE_SEARCH_CONFIGURATION = gql`
    mutation updateSearchConfiguration(
        $id: ID!,
        $name: String,
        $objectType: String,
        $options: [SearchConfigurationOptionInput]
    ) {
        updateSearchConfiguration(
            id: $id,
            name: $name,
            objectType: $objectType,
            options: $options
        ) {
            success
            errors
        }
    }
`;

export const updateSearchConfigurationOptions = (client: TimGraphApollo, sc: SearchConfiguration): Promise<MutationResult> => {
    const params = {
        id: sc.id,
        name: sc.name,
        objectType: sc.objectType?.id,
        options: sc.options.map(o => ({
            name: o.name,
            path: o.path,
            conditionType: o.conditionType.type,
            conditionValue: o.conditionType.id
        }))
    };
    return client.mutate({
        mutation: MUTATION_UPDATE_SEARCH_CONFIGURATION,
        variables: params
    }).then(result => result.data.updareSearchConfiguration);
};