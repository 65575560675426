import gql from "graphql-tag";
import {TimGraphApollo} from "tim-bridge";
import {MutationResult, SearchConfiguration} from "../../shared/types";

const MUTATION_CREATE_SEARCH_CONFIGURATION = gql`
    mutation createSearchConfiguration(
        $name: String!,
        $objectType: String!,
    ) {
        createSearchConfiguration(
            name: $name,
            objectType: $objectType,
        ) {
            success
            errors
        }
    }
`;

export const createSearchConfiguration = (client: TimGraphApollo, sc: SearchConfiguration): Promise<MutationResult> => {
    return client.mutate({
        mutation: MUTATION_CREATE_SEARCH_CONFIGURATION,
        variables: {
            name: sc.name,
            objectType: sc.objectType.id,
            options: []
        }
    }).then(result => result.data.createSearchConfiguration);
};
