import {TimGraphApollo} from "tim-bridge";
import {SearchConfiguration} from "../../shared/types";
import gql from "graphql-tag";

const QUERY_SEARCH_CONFIGURATION_BY_ID = gql`
    query searchConfiguration($id: ID!){
        searchConfiguration(id: $id){
            id
            name
            objectType {
                id
                name
            }
            options {
                id
                name
                path
                conditionType {
                    type
                    id
                    name
                }
            }
        }
    }
`;

export const getSearchConfigurationById = (client: TimGraphApollo, id: string): Promise<SearchConfiguration> => {
    return client.query({
        query: QUERY_SEARCH_CONFIGURATION_BY_ID,
        variables: {id: id},
        fetchPolicy: "network-only"}
    ).then(result => result.data.searchConfiguration);
};