const idGenerator: Iterator<number> = (function* forever(n: number = 0) {
    while (true) yield n++;
})(256);

export function getUUID(prefix: string = "auto:"): string {
    return prefix + idGenerator.next().value.toString(16);
}

export function getDataUpdater<T>(data: T, setData: (T) => void) {
    return (field: keyof T, value: any) => setData({...data, [field]: value});
}