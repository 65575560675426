import * as React from "react";
import {FC, useState} from "react";
import TypeaheadSearch from "./TypeaheadSearch";
import {
    createStiboTheme,
    createStyles,
    defaultStiboTheme,
    StyleRules,
    ThemeProvider,
    withStiboStyles,
    WithStiboStyles
} from "tim-ui";
import {withBridgeCallbacks, WithGraphClient, withGraphqlEndpoint} from "tim-bridge";
import {GET_ATTRIBUTES_AND_REFTYPES, GET_OBJECT_TYPES} from "./Queries";
import {IntlProvider} from "react-intl";
import {StiboThemeProvider} from "@stibo/value-components";
import {ConditionType, DataSelection} from "../shared/types";
const styles = (): StyleRules => createStyles({});
const stiboTheme = createStiboTheme(defaultStiboTheme.colors);

const TypeaheadTestScreen: FC<WithStiboStyles & WithGraphClient> = (props: WithStiboStyles & WithGraphClient) => {

    const [objectType, setObjectType] = useState<DataSelection>(undefined);
    const [attribute, setAttribute] = useState<DataSelection>(undefined);

    return <div>
        <TypeaheadSearch title={"Object type"} query={GET_OBJECT_TYPES} onChange={setObjectType} value={objectType} />
        <TypeaheadSearch title={"Attributes and reference types"} query={GET_ATTRIBUTES_AND_REFTYPES} onChange={setAttribute} value={attribute} />
    </div>;
};

const TypeaheadTestScreenWrapper = (props) => (
    <IntlProvider locale={"en-us"}>
        <ThemeProvider stiboTheme={stiboTheme}>
            <StiboThemeProvider>
                <TypeaheadTestScreen {...props} />
            </StiboThemeProvider>
        </ThemeProvider>
    </IntlProvider>
);

export default withStiboStyles(styles)(
    withBridgeCallbacks(TypeaheadTestScreenWrapper)
);
